import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { createModule } from 'vuexok'
import router from '@/router'
import store from '@/store'
import { EmailAuditDetailState } from './types'
import { fetchMailLogDetail, resentEmail } from '@/api/email'

// eslint-disable-next-line import/prefer-default-export
export const appEmailAuditDetail = createModule(
  'appEmailAuditDetail',
  {
    namespaced: true,
    state: {
      content: '',
      sender: '',
      recipient: '',
      subject: '',
      isButtonDisabled: false,
    } as EmailAuditDetailState,
    getters: {
      getContent: state => state.content,
      getSender: state => state.sender,
      getRecipient: state => state.recipient,
      getSubject: state => state.subject,
      getIsButtonDisabled: state => state.isButtonDisabled,
    },
    mutations: {
      SET_CONTENT(state, val: string) {
        state.content = val
      },
      SET_SENDER(state, val: string) {
        state.sender = val
      },
      SET_RECIPIENT(state, val: string) {
        state.recipient = val
      },
      SET_SUBJECT(state, val: string) {
        state.subject = val
      },
      SET_IS_BUTTON_DISABLED(state, val: boolean) {
        state.isButtonDisabled = val
      },
      RESET_STATE(state) {
        state.content = ''
        state.sender = ''
        state.recipient = ''
        state.subject = ''
        state.isButtonDisabled = false
      },
    },
    actions: {
      async fetchMailLogDetail(ctx, id) {
        try {
          const response = await fetchMailLogDetail(id)
          appEmailAuditDetail.mutations.SET_CONTENT(response.data.data.body)
          appEmailAuditDetail.mutations.SET_SENDER(response.data.data.from)
          appEmailAuditDetail.mutations.SET_RECIPIENT(response.data.data.to)
          appEmailAuditDetail.mutations.SET_SUBJECT(response.data.data.subject)
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching mail log detail',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      },
      async resent(ctx, id) {
        try {
          appEmailAuditDetail.mutations.SET_IS_BUTTON_DISABLED(true)
          await resentEmail(id)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Email was sent successfully',
              icon: 'ThumbsUpIcon',
              variant: 'info',
            },
          })
          appEmailAuditDetail.mutations.SET_IS_BUTTON_DISABLED(false)
          router.back()
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error sending email',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        } finally {
          appEmailAuditDetail.mutations.SET_IS_BUTTON_DISABLED(false)
        }
      },
    },
  },
)

appEmailAuditDetail.register(store)
