




























































































import Vue from 'vue'

import {
  BRow, BCol, BCard, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { appEmailAuditDetail } from '@/store/modules/app-email-audit-detail'

export default Vue.extend({
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      mailLogID: this.$router.currentRoute.params.id,
    }
  },
  computed: {
    content() {
      return appEmailAuditDetail.getters.getContent
    },
    sender() {
      return appEmailAuditDetail.getters.getSender
    },
    recipient() {
      return appEmailAuditDetail.getters.getRecipient
    },
    subject() {
      return appEmailAuditDetail.getters.getSubject
    },
    isButtonDisabled() {
      return appEmailAuditDetail.getters.getIsButtonDisabled
    },
  },
  async created() {
    if (this.mailLogID) {
      appEmailAuditDetail.actions.fetchMailLogDetail(this.mailLogID)
    }
  },
  beforeDestroy() {
    appEmailAuditDetail.mutations.RESET_STATE()
  },
  methods: {
    async resent() {
      await appEmailAuditDetail.actions.resent(this.mailLogID)
    },
  },
})
