import axios from '@axios'

export const fetchEmailAuditList = async (params: any): Promise<any> => {
  const res = await axios.get('/auth/mail-logs', { params })
  return res
}

export const fetchMailLogDetail = async (id: string): Promise<any> => {
  const res = await axios.get(`/auth/mail-logs/${id}`)
  return res
}

export const fetchUserType = async (): Promise<any> => {
  const res = await axios.get('/auth/mail-logs-user-types')
  return res
}

export const fetchTriggers = async (): Promise<any> => {
  const res = await axios.get('/auth/mail-logs-triggers')
  return res
}

export const resentEmail = async (id: string): Promise<any> => {
  const res = await axios.get(`/auth/resend-email/${id}`)
  return res
}
